import { Link } from 'gatsby';
import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Handbag } from '@styled-icons/bootstrap/Handbag';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Instagram } from '@styled-icons/boxicons-logos/Instagram';
import { SnipcartContext } from 'gatsby-plugin-snipcart-advanced/context';
import NavDropdown from './NavDropdown/NavDropdown';
import Logo from './Logo';

const NavStyles = styled.nav`
  position: relative;
  z-index: 1;
  margin-bottom: 3rem;
  list-style: none;
  display: grid;
  grid-template-columns: 0.1fr auto 0.1fr;
  grid-gap: 2rem;
  align-items: center;
  justify-content: space-around;
`;

const CartStyles = styled.div`
  cursor: pointer;
`;

const CartQuantityStyles = styled.div`
  width: 16px;
  height: 16px;
  line-height: 14px;
  border-radius: 50%;
  font-size: 10px;
  color: #fff;
  text-align: center;
  background: var(--black);
  position: relative;
  top: 8px;
  left: 20px;
`;

const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2px;
`;

export default function Nav() {
  const { state } = useContext(SnipcartContext);
  const { cartQuantity } = state;

  return (
    <NavStyles>
      <NavDropdown />
      <Link to="/">
        <Logo />
      </Link>
      <IconWrapper>
        <div>
          <a
            href="https://www.instagram.com/annalinneadesigns/"
            target="_blank"
            rel="noreferrer noopener"
          >
            <Instagram color="#424242" size={25} />
          </a>
        </div>
        <div>
          <p>|</p>
        </div>
        <button
          className="snipcart-checkout"
          type="button"
          style={{ padding: '0 0 22px 0' }}
        >
          <CartStyles>
            <CartQuantityStyles>{cartQuantity || 0}</CartQuantityStyles>
            <Handbag color="#424242" size={25} />
          </CartStyles>
        </button>
      </IconWrapper>
    </NavStyles>
  );
}
