import React, { useEffect } from 'react';
import styled from 'styled-components';
import 'normalize.css';
import Nav from './Nav';
import Footer from './Footer';
import GlobalStyles from '../styles/GlobalStyles';
import Typography from '../styles/Typography';

const SiteBorderStyles = styled.div`
  max-width: 2880px;
  margin: 10%;
  margin-top: clamp(2rem, 10vw, 1rem);
  background: var(--background);
  background-size: 1500px;
  padding-top: clamp(5px, 1vw, 10px);
`;

const ContentStyles = styled.div`
  background: var(--background);
`;

function fireFacebookPurchaseEvent(cart) {
  const itemIds = cart.items.items.map((i) => i.id);
  if (typeof window !== 'undefined') {
    if (window.fbq != null) {
      window.fbq('track', 'Purchase', {
        content_type: 'product',
        content_ids: itemIds,
        value: cart.subtotal,
        currency: 'GBP',
      });
    }
  }
}

export default function Layout({ children }) {
  useEffect(() => {
    const { Snipcart } = window;
    if (!Snipcart) return;
    Snipcart.events.on('cart.confirmed', (cartConfirmResponse) => {
      fireFacebookPurchaseEvent(cartConfirmResponse);
    });
  }, []);
  return (
    <>
      <GlobalStyles />
      <Typography />
      <SiteBorderStyles>
        <ContentStyles>
          <Nav />
          {children}
          <Footer />
        </ContentStyles>
      </SiteBorderStyles>
    </>
  );
}
