import React, { useState } from 'react';
import styled from 'styled-components';
import addToMailchimp from 'gatsby-plugin-mailchimp';

const EmailFormStyles = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 1rem 0 1rem;

  h2 {
    font-size: 2rem;
    text-transform: uppercase;
    padding-bottom: 2rem;
  }
  h3 {
    margin: auto;
    margin-bottom: 1rem;
    font-size: 1.75rem;
  }

  input {
    margin: 1rem;
    color: var(--black);
    border: 1px solid var(--black);
    /* margin-top: 1rem; */
    /* margin-bottom: 1rem; */
  }

  button,
  input {
    padding: 1rem 1.5rem;
    max-width: 90%;
    font-size: 1.75rem;
  }

  button {
    background-color: var(--black);
    color: var(--white);
    margin: auto;
    /* margin-top: 1rem; */
    &:hover {
      cursor: pointer;
      background: var(--rose);
    }
  }
`;

const EmailInputStyles = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
`;

export default function MailChimpSignupForm() {
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [subscriptionResult, setSubscriptionResult] = useState({});

  const handleSubmit = async (e) => {
    e.preventDefault();
    const result = await addToMailchimp(email, {
      FNAME: firstName,
      LNAME: lastName,
    });
    setSubscriptionResult(result);
  };

  if (JSON.stringify(subscriptionResult) === '{}') {
    return (
      <form onSubmit={handleSubmit}>
        <EmailFormStyles>
          <h2>Sign Up For Our Newsletter</h2>
          <h3 className="center">
            Sign up and be the first to hear about new products & special
            offers. You will receive an exclusive code to get 10% off your first
            order.
          </h3>
          <EmailInputStyles>
            <input
              placeholder="First Name"
              name="firstName"
              type="text"
              onChange={(e) => setFirstName(e.target.value)}
            />
            <input
              placeholder="Last Name"
              name="lastName"
              type="text"
              onChange={(e) => setLastName(e.target.value)}
            />
            <input
              placeholder="Email"
              name="email"
              type="text"
              onChange={(e) => setEmail(e.target.value)}
            />
          </EmailInputStyles>
          <button type="submit">Subscribe</button>
        </EmailFormStyles>
      </form>
    );
  }
  if (subscriptionResult.result === 'success') {
    return <div>Success! Thank you for Subscribing!</div>;
  }
  return (
    <>
      <div style={{ padding: '1rem' }}>Error subscribing.</div>
      <div style={{ padding: '1rem' }}>{subscriptionResult.msg}</div>
      <div style={{ padding: '1rem' }}>
        Please try reloading the page and trying again?
      </div>
    </>
  );
}
