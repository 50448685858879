import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';
import Burger from './Burger';
import Menu from './Menu';

const StyledDropdown = styled.div``;

export default function NavDropdown() {
  const [open, setOpen] = useState(false);
  const node = useRef();
  useOnClickOutside(node, () => setOpen(false));

  return (
    <StyledDropdown ref={node}>
      <Burger open={open} setOpen={setOpen} />
      <Menu open={open} setOpen={setOpen} />
    </StyledDropdown>
  );
}
