/** store app for snipcart stock * */
import React, { createContext, useReducer, useEffect } from 'react';
import axios from 'axios';

const urlStock = '/.netlify/functions/getStock';

export const initialState = {
  snipcartStock: [],
};

// create context for dispatch
export const SnipcartStockContext = createContext(initialState);

const reducer = (state, action) => {
  switch ((state, action.type)) {
    case 'setSnipcartStock':
      return { ...state, ...{ snipcartStock: action.payload } };
    default:
      return state;
  }
};

const SnipcartStockProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  // update state
  useEffect(() => {
    axios
      .get(urlStock)
      .then(function ({ data }) {
        if (Array.isArray(data)) {
          dispatch({ type: 'setSnipcartStock', payload: data });
        }
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  }, []);

  return (
    <SnipcartStockContext.Provider value={state}>
      {children}
    </SnipcartStockContext.Provider>
  );
};

export default SnipcartStockProvider;
