import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import MailChimpSignupForm from './MailChimpSignupForm';

const SignupFormStyles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 2px solid var(--grey);
  padding-top: 5rem;
`;

const FooterLinks = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  gap: 2rem;
  padding-top: 5rem;
  padding-bottom: 2rem;
  a {
    min-width: 100px;
  }
`;

const CopyrightStyles = styled.div`
  padding-bottom: 2rem;
`;

export default function Footer() {
  return (
    <footer className="center">
      <SignupFormStyles>
        <MailChimpSignupForm />
      </SignupFormStyles>
      <FooterLinks>
        <Link to="/our-story" style={{ fontSize: '2rem' }}>
          Our Story
        </Link>
        <Link to="/delivery" style={{ fontSize: '2rem' }}>
          Delivery
        </Link>
        <Link to="/returns" style={{ fontSize: '2rem' }}>
          Returns
        </Link>
        <Link to="/contact-us" style={{ fontSize: '2rem' }}>
          Contact Us
        </Link>
      </FooterLinks>
      <CopyrightStyles>
        <p>&copy; Anna Linnea Designs {new Date().getFullYear()} </p>
      </CopyrightStyles>
    </footer>
  );
}
