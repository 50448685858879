import React from 'react';
import styled from 'styled-components';

const StyledH1 = styled.h1`
  position: relative;
  bottom: 1rem;
  @media (max-width: 600px) {
    bottom: 0;
  }
`;

export default function Logo() {
  return <StyledH1 className="logo">Anna linnea</StyledH1>;
}
