import React from 'react';
import styled from 'styled-components';

const StyledBurgerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const StyledBurger = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  box-shadow: none;
  text-shadow: none;
  z-index: 10;

  &:focus {
    outline: none;
  }

  span {
    width: 2rem;
    height: 0.2rem;
    background: var(--black);
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;
    :nth-child(1) {
      transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
    }
    :nth-child(2) {
      opacity: ${({ open }) => (open ? '0' : '1')};
    }
    :nth-child(3) {
      transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
    }
  }
`;

const StyledLabel = styled.div`
  color: var(--black);
  padding-right: 10px;
  line-height: 100%;
  @media (max-width: 600px) {
    display: none;
  }
`;

export default function Burger({ open, setOpen }) {
  const isExpanded = !!open;

  function toggleMenu() {
    setTimeout(() => setOpen(!open), 650);
  }

  return (
    <StyledBurgerContainer onClick={toggleMenu}>
      <StyledLabel>About</StyledLabel>
      <StyledBurger
        aria-label="Toggle menu"
        aria-expanded={isExpanded}
        open={open}
        onClick={() => setOpen(!open)}
      >
        <span />
        <span />
        <span />
      </StyledBurger>
    </StyledBurgerContainer>
  );
}
