import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import { Animated } from 'react-animated-css';

export const StyledMenu = styled.nav`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  background: var(--white);
  outline: solid var(--black);
  padding: 2rem;
  margin-top: 5px;
`;

const NavLink = styled.div`
  cursor: pointer;
  font-size: 2rem !important;
  padding: 1rem 0;
  color: var(--black);
  transition: color 0.3s linear;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    font-size: 1.5rem;
    text-align: center;
  }

  &:hover {
    color: var(--rose);
  }
`;

export default function Menu({ open, setOpen }) {
  const [noDisplay, setNoDisplay] = useState(!open);

  function navToPage(e, page) {
    e.preventDefault();
    setOpen(false);
    navigate(page);
  }

  const style = noDisplay ? { display: 'none' } : null;

  useEffect(() => {
    if (!open) setTimeout(() => setNoDisplay(true), 650);
    else setNoDisplay(false);
  }, [open]);

  return (
    <Animated
      animationIn="fadeIn"
      animationOut="fadeOut"
      isVisible={open}
      style={style}
    >
      {/* <StyledMenu open={open} aria-hidden={!isHidden}> */}
      <StyledMenu>
        <NavLink onClick={(e) => navToPage(e, '/our-story')} aria-hidden="true">
          Our Story
        </NavLink>
        <NavLink onClick={(e) => navToPage(e, '/delivery')} aria-hidden="true">
          Delivery
        </NavLink>
        <NavLink onClick={(e) => navToPage(e, '/returns')} aria-hidden="true">
          Returns
        </NavLink>
        <NavLink
          onClick={(e) => navToPage(e, '/contact-us')}
          aria-hidden="true"
        >
          Contact Us
        </NavLink>
      </StyledMenu>
    </Animated>
  );
}
