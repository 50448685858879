import { createGlobalStyle } from 'styled-components';
import '../font/font.css';

const Typography = createGlobalStyle`
  html {
    /*
    fonts to consider
    EB Garamond
    work sans
    baskerville
     */
    font-family: "Sorts Mill Goudy","Baskerville Old Face","Hoefler Text",Garamond,"Times New Roman",serif;
    /* font-family: Baskerville,"Baskerville Old Face","Hoefler Text",Garamond,"Times New Roman",serif; */
    color: var(--black);
  }
  p, li {
    letter-spacing: 0.5px;
  }
  h1,h2,h3,h4,h5,h6 {
    font-weight: normal;
    margin: 0;
  }
  a {
    color: var(--black);
    text-decoration: none
  }
  mark, .mark {
    /* background: var(--yellow); */
    padding: 0 2px 2px 2px;
    margin: 0;
    display: inline;
    line-height: 1;
  }

  .center {
    text-align: center;
  }

  .tilt {
    transform: rotate(-2deg);
  }
  .logo {
    font-family: "Dawning of A New Day", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 3em;
    @media(max-width: 600px){
      font-size: 1.5em
    }
  }
`;

export default Typography;
