import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  :root {
    --rose: #bc7578;
    --black: #424242;
    --white: #fff;
    --grey: #efefef;
    --darkgrey: #3D4849;
    --background: #e8e2e0;
    --button: #e8e2e0;
  }
  html, body {
    margin: 0;
    padding: 0;
    background: var(--background);
  }

  *, *::after, *::before {
    box-sizing: border-box;
  }

  html {
    font-size: 10px;
  }

  body {
    height: 100vh;
    text-rendering: optimizeLegibility;
    font-size: 2rem;
  }

  fieldset {
    border-color: rgba(0,0,0,0.1);
    border-width: 1px;
  }

  button {
    background: none;
    color: white;
    border: 0;
    padding: 0.6rem 1rem;
    border-radius: 2px;
    cursor: pointer;
    --cast: 2px;
    text-shadow: 0.5px 0.5px 0 rgba(0,0,0,0.2);
    transition: all 0.2s;
    &:hover {
      --cast: 4px;
    }
  }

  .gatsby-image-wrapper img[src*=base64\\,] {
    image-rendering: -moz-crisp-edges;
  }

  /* Scrollbar Styles */
  body::-webkit-scrollbar {
    width: 12px;
  }
  html {
    scrollbar-width: thin;
    scrollbar-color: var(--rose) var(--white);
  }
  body::-webkit-scrollbar-track {
    background: var(--white);
  }
  body::-webkit-scrollbar-thumb {
    background-color: var(--rose) ;
    border-radius: 6px;
    border: 3px solid var(--white);
  }

  hr {
    border: 0;
    height: 8px;
    background-size: 1500px;
  }

  img {
    max-width: 100%;
  }

  .tilt {
    transform: rotate(-2deg);
    position: relative;
    display: inline-block;
  }

  .snipcart-layout {
    background: white !important;
  }

  .snipcart-cart-header {
    background: white !important;
  }

  .snipcart-cart__footer {
    background: white !important;
  }

  .snipcart-modal__container {
    background: white !important;
  }

  .snipcart-cart__content {
    background: white !important;
  }

  .snipcart-cart-header__close-button {
    box-shadow: none !important;
  }

  .snipcart-item-line__container {
    box-shadow: 0px 0px 11px 4px whitesmoke;
  }
  .blockParent {
    display: flex;
    align-items: flex-start;
  }

  .blockParent ul {
    list-style: none;
  }

  .blockParent ul > li {
    margin-bottom: 3rem;
    font-size: large;
  }

`;

export default GlobalStyles;
