import React from 'react';
import Layout from './src/components/Layout';
import SnipcartStockProvider from './src/components/SnipcartStockContext';

export function wrapPageElement({ element, props }) {
  return <Layout {...props}>{element}</Layout>;
}

export function wrapRootElement({ element }) {
  return <SnipcartStockProvider>{element}</SnipcartStockProvider>;
}
